import React, { useRef } from 'react';
import styles from './ValueProps.module.scss';
import Typography from '~/components/shared/Typography';
import {
  ConferenceRebrand,
  ConnectRebrand,
  GamingRebrand,
  StreamRebrand,
  TrophyRebrand,
  PersonRebrand,
  UnlimitedRebrand,
  DollarRebrand,
  PlayRebrand,
  MapRebrand,
  SatelliteRebrand,
  Unlimited,
} from '~/components/icons';
import useIntersectionObserver from '~/helpers/hooks/useIntersectionObserver';
import { fireElementViewedEvent } from '~/helpers/utils/fireTagular';
import LegalAnnotation from '~/components/shared/LegalAnnotation';
import { useMonarchPreampPlacementContext } from '@redventures/cohesion-utils-react';

const BASE_DATA = {
  backgroundColor: 'white',
  body1TextColor: '#333',
  body2TextColor: '#333',
  body3TextColor: '#333',
  disclaimer1: '*100 Mbps speeds available on select plans in select areas.',
  disclaimer2: '*100 Mbps speeds available on select plans in select areas.',
  disclaimer3: '*100 Mbps speeds available on select plans in select areas.',
  disclaimerToggle1: false,
  disclaimerToggle2: false,
  disclaimerToggle3: false,
  header1TextColor: '#333',
  header2TextColor: '#333',
  header3TextColor: '#333',
  headline: 'Now you can do it all, wherever you live',
  headlineTextColor: '#0096fa',
  icon1: 'dish',
  icon2: 'play',
  icon3: 'trophy',
  iconColor: '#0091c4',
  prop1Subhead1:
    'Hughesnet is faster than ever, with download speeds up to 100 Mbps',
  prop1Subhead1Legal: 'generic-speed',
  prop1Subhead1LegalToggle: true,
  prop1Subhead2: 'and includes a Wi-Fi Modem featuring Wi-Fi 6 technology.',
  prop1Subhead2Legal: 'change to legal key',
  prop1Subhead2LegalToggle: false,
  prop1Subhead2Toggle: true,
  prop2Subhead1: 'Every plan comes with unlimited data,',
  prop2Subhead1Legal: 'data-limit',
  prop2Subhead1LegalToggle: true,
  prop2Subhead2:
    "so you'll never get cut off or pay overage fees. Stream HD video and music, and do video-conferencing.",
  prop2Subhead2Legal: 'change to legal key',
  prop2Subhead2LegalToggle: false,
  prop2Subhead2Toggle: true,
  prop3Subhead1:
    'Only the Hughesnet Fusion plan combines the reach of satellite with the responsiveness of low-latency wireless, so you can enjoy gaming and playing online like never before. Check for availability.',
  prop3Subhead1Legal: 'change to legal key',
  prop3Subhead1LegalToggle: false,
  prop3Subhead2: 'change',
  prop3Subhead2Legal: 'change to legal key',
  prop3Subhead2LegalToggle: false,
  prop3Subhead2Toggle: false,
  propHeading1:
    'Connect: Experience a fast and reliable connection wherever you live.',
  propHeading1Legal: 'change to legal key',
  propHeading1LegalToggle: false,
  propHeading2: 'Stream: Watch more video and do more video-conferencing',
  propHeading2Legal: 'change to legal key',
  propHeading2LegalToggle: false,
  propHeading3: 'Play: Enjoy online gaming with a low-latency Fusion plan',
  propHeading3Legal: 'change to legal key',
  propHeading3LegalToggle: false,
};

export default function ValueProps() {
  const valuePropsSection = useRef();
  const { data: monarchData } = useMonarchPreampPlacementContext();
  const observeSections = useIntersectionObserver(
    [valuePropsSection],
    (el) =>
      fireElementViewedEvent(
        'value props',
        'value props section',
        'value props',
        headline
      ),
    undefined,
    // Fire tagular only when the full element is visible
    { threshold: 1.0 }
  );

  const {
    backgroundColor,
    headlineTextColor,
    icon1,
    icon2,
    icon3,
    header3TextColor,
    headline,
    propHeading1,
    propHeading2,
    propHeading3,
    disclaimer1,
    disclaimerToggle1,
    disclaimer2,
    disclaimerToggle2,
    disclaimer3,
    disclaimerToggle3,
    propHeading1LegalToggle,
    propHeading1Legal,
    propHeading2LegalToggle,
    propHeading2Legal,
    propHeading3LegalToggle,
    propHeading3Legal,
    prop1Subhead1,
    prop1Subhead1LegalToggle,
    prop1Subhead1Legal,
    prop1Subhead2Toggle,
    prop1Subhead2,
    prop1Subhead2LegalToggle,
    prop1Subhead2Legal,
    prop2Subhead1,
    prop2Subhead1LegalToggle,
    prop2Subhead1Legal,
    prop2Subhead2Toggle,
    prop2Subhead2,
    prop2Subhead2LegalToggle,
    prop2Subhead2Legal,
    prop3Subhead1,
    prop3Subhead1LegalToggle,
    prop3Subhead1Legal,
    prop3Subhead2Toggle,
    prop3Subhead2,
    prop3Subhead2LegalToggle,
    prop3Subhead2Legal,
  } = { ...BASE_DATA, ...monarchData };

  const getIcon = (icon) => {
    switch (icon) {
      case 'conference':
        return <ConferenceRebrand />;
      case 'dish':
        return <ConnectRebrand />;
      case 'gaming':
        return <GamingRebrand />;
      case 'play':
        return <StreamRebrand />;
      case 'trophy':
        return <TrophyRebrand />;
      case 'unlimited':
        return <UnlimitedRebrand />;
      case 'dollar':
        return <DollarRebrand />;
      case 'person':
        return <PersonRebrand />;
      case 'stream':
        return <PlayRebrand />;
      case 'map':
        return <MapRebrand />;
      case 'satellite':
        return <SatelliteRebrand />;
      case 'newUnlimited':
        return <Unlimited />;
      default:
        return <ConferenceRebrand />;
    }
  };

  return (
    <section
      ref={valuePropsSection}
      className={styles.container}
      style={{ backgroundColor: backgroundColor }}
    >
      <Typography variant='h2' className={styles.headline}>
        <span style={{ color: headlineTextColor }}>{headline}</span>
      </Typography>
      <div className={styles.contentWrapper}>
        {/* Prop 1 with Places Icon */}
        <div>
          {getIcon(icon1)}
          <div className={styles.content}>
            <Typography variant='h3' className={styles.heading}>
              <span dangerouslySetInnerHTML={{ __html: propHeading1 }} />
              {propHeading1LegalToggle && (
                <LegalAnnotation annotation={propHeading1Legal} />
              )}
            </Typography>
            <Typography variant='p' className={styles.subheading}>
              <span dangerouslySetInnerHTML={{ __html: prop1Subhead1 }} />
              {prop1Subhead1LegalToggle && (
                <LegalAnnotation annotation={prop1Subhead1Legal} />
              )}{' '}
              {prop1Subhead2Toggle && (
                <span dangerouslySetInnerHTML={{ __html: prop1Subhead2 }} />
              )}
              {prop1Subhead2LegalToggle && (
                <LegalAnnotation annotation={prop1Subhead2Legal} />
              )}
            </Typography>
            {disclaimerToggle1 && (
              <Typography variant='disclaimer' className={styles.disclaimer}>
                <span>{disclaimer1}</span>
              </Typography>
            )}
          </div>
        </div>
        {/* Prop 2 with WiFi Signal Icon */}
        <div>
          {getIcon(icon2)}
          <div className={styles.content}>
            <Typography variant='h3' className={styles.heading}>
              <span dangerouslySetInnerHTML={{ __html: propHeading2 }} />
              {propHeading2LegalToggle && (
                <LegalAnnotation annotation={propHeading2Legal} />
              )}
            </Typography>
            <Typography variant='p' className={styles.subheading}>
              <span dangerouslySetInnerHTML={{ __html: prop2Subhead1 }} />
              {prop2Subhead1LegalToggle && (
                <LegalAnnotation annotation={prop2Subhead1Legal} />
              )}{' '}
              {prop2Subhead2Toggle && (
                <span dangerouslySetInnerHTML={{ __html: prop2Subhead2 }} />
              )}
              {prop2Subhead2LegalToggle && (
                <LegalAnnotation annotation={prop2Subhead2Legal} />
              )}
            </Typography>
            {disclaimerToggle2 && (
              <Typography variant='disclaimer' className={styles.disclaimer}>
                <span>{disclaimer2}</span>
              </Typography>
            )}
          </div>
        </div>
        {/* Prop 3 with ThumbsUp Icon */}
        <div>
          {getIcon(icon3)}
          <div className={styles.content} style={{ color: header3TextColor }}>
            <Typography variant='h3' className={styles.heading}>
              <span dangerouslySetInnerHTML={{ __html: propHeading3 }} />
              {propHeading3LegalToggle && (
                <LegalAnnotation annotation={propHeading3Legal} />
              )}
            </Typography>
            <Typography variant='p' className={styles.subheading}>
              <span dangerouslySetInnerHTML={{ __html: prop3Subhead1 }} />
              {prop3Subhead1LegalToggle && (
                <LegalAnnotation annotation={prop3Subhead1Legal} />
              )}{' '}
              {prop3Subhead2Toggle && (
                <span dangerouslySetInnerHTML={{ __html: prop3Subhead2 }} />
              )}
              {prop3Subhead2LegalToggle && (
                <LegalAnnotation annotation={prop3Subhead2Legal} />
              )}
            </Typography>
            {disclaimerToggle3 && (
              <Typography variant='disclaimer' className={styles.disclaimer}>
                <span>{disclaimer3}</span>
              </Typography>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
